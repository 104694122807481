import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {useStaticQuery, graphql} from "gatsby";
import Img from "gatsby-image";


const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "large Epic.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
  <header
    style={{
      background: `#87a086`,
      marginBottom: `1.45rem`,
      minHeight: `155px`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0, position: `absolute`, top: `0`, left: `0` }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <span><Img fixed={data.logo.childImageSharp.fixed} alt="Epic Floral Co Logo"/></span>
          <span aria-hidden={true} style={{display: `none`}}>Epic Floral Co</span>
        </Link>
      </h1>
    </div>
  </header>
)};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
