/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Paper} from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby"
import {Instagram} from "@material-ui/icons"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
        style={{
          display: `flex`,
          minHeight: `100vh`,
          flexDirection: `column`
        }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          width: `960px`,
          padding: `0 1.0875rem 1.45rem`,
          flex: 1
        }}
      >

        <main>
          <Paper elevation={2} style={{padding: `5px 0 0 5px`}}>{children}</Paper>
        </main>
      </div>
      <footer
          style={{
            background: `black`,
            width: `100%`,
            color: `white`,
            paddingTop: `20px`
          }}
      >
        <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `2px 1.0875rem 1.45rem`,
            }}
        >
          © {new Date().getFullYear()}, Epic Floral Co.
          <span style={{verticalAlign: `middle`, paddingLeft: `5px`}}><Link to="https://instagram.com/epicfloral" style={{color: `white`}}><Instagram /></Link></span>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
